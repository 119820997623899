// import { useEffect, useState } from 'react'
// import Autocomplete from '@mui/material/Autocomplete'
// import usePlacesAutocomplete, {
//   getGeocode,
//   getLatLng,
// } from 'use-places-autocomplete'
// import { TextField, Theme } from '@mui/material'
// import { makeStyles } from '@mui/styles'
// import UIConstants from '../../constants/ui-constants'


// const useStyle = makeStyles((theme: Theme) => ({
//   locationSelector: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: UIConstants.primaryBgColor,
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: UIConstants.primaryBgColor,
//       },
//     },
//     '& .MuiInputLabel-root': {
//       color: UIConstants.primaryBgColor,
//     },
//   },
// }))

// export default function LocationSelector(props: any) {
//   const classes = useStyle()
//   const {
//     setValue,
//     suggestions: { data },
//   } = usePlacesAutocomplete({ debounce: 300 })

//   const [currentVal, setCurrentVal] = useState<string>('')
//   useEffect(() => {
//     if (props.value) setCurrentVal(props.value)
//   }, [props])

//   return (
//     <Autocomplete
//       id="asynchronous-location"
//       freeSolo
//       options={data}
//       inputValue={currentVal}
//       fullWidth
//       getOptionLabel={(data) => data.description}
//       filterOptions={(x) => x}
//       disabled={props.disabled}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label={props.label}
//           value={currentVal}
//           size={props.size}
//           error={props.error}
//           helperText={props.helperText}
//           className={classes.locationSelector}
//           onChange={(e) => setCurrentVal(e.target.value)}
//         />
//       )}
//       onInputChange={(_e, value) => {
//         setValue(value)
//         setCurrentVal(value)
//       }}
//       onChange={(_e, value) => {
//         const location = value
//         getGeocode({ address: value.description }).then((results) => {
//           const { lat, lng } = getLatLng(results[0])
//           location.lat = lat
//           location.lng = lng
//         })
//         location.label = location.description
//         props.setSelectedLocationData &&
//           props.setSelectedLocationData(JSON.stringify(location))
//         props.onChange(location.description)
//       }}
//     />
//   )
// }

// import React, { useEffect, useRef, useState } from 'react'
// import { Autocomplete } from '@react-google-maps/api'
// import { TextField, Theme } from '@mui/material'
// import { makeStyles } from '@mui/styles'
// import UIConstants from '../../constants/ui-constants'

// export default function LocationSelector(props: any) {
//   const useStyle = makeStyles((theme: Theme) => ({
//   locationSelector: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: UIConstants.primaryBgColor,
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: UIConstants.primaryBgColor,
//       },
//     },
//     '& .MuiInputLabel-root': {
//       color: UIConstants.primaryBgColor,
//     },
//   },
// }))

//   const classes = useStyle()
  
//   const [currentVal, setCurrentVal] = useState<string>('')
//   const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

//   useEffect(() => {
//     if (props.value) setCurrentVal(props.value)
//   }, [props.value])

//   const handlePlaceSelected = () => {
//     if (!autocompleteRef.current) return

//     const place = autocompleteRef.current.getPlace()
//     if (!place.geometry || !place.geometry.location) {
//       console.error('Place has no geometry')
//       return
//     }

//     const location = {
//       description: place.formatted_address,
//       lat: place.geometry.location.lat(),
//       lng: place.geometry.location.lng(),
//     }

//     props.setSelectedLocationData?.(JSON.stringify(location))
//     props.onChange?.(location.description)
//   }

//   return (
//     <Autocomplete
//       id="asynchronous-location"
//       freeSolo
//       options={data}
//       inputValue={currentVal}
//       fullWidth
//       getOptionLabel={(data) => data.description}
//       filterOptions={(x) => x}
//       disabled={props.disabled}
//       onLoad={(autocomplete) => {
//         autocompleteRef.current = autocomplete
//       }}
//       onPlaceChanged={handlePlaceSelected}
//     >
//       <TextField
//         label={props.label}
//         value={currentVal}
//         size={props.size}
//         error={props.error}
//         helperText={props.helperText}
//         className={classes.locationSelector}
//         fullWidth
//         onChange={(e) => setCurrentVal(e.target.value)}
//       />
//     </Autocomplete>
//   )
// }


import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete } from '@react-google-maps/api'
import { TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../constants/ui-constants'

const useStyle = makeStyles((theme: Theme) => ({
  locationSelector: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
    '& .MuiInputLabel-root': {
      color: UIConstants.primaryBgColor,
    },
  },
  autocompleteWrapper: {
    width: '100%', // Ensure the wrapper takes the full width
  },
}))

export default function LocationSelector(props: any) {
  const classes = useStyle()
  const [currentVal, setCurrentVal] = useState<string>('')
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

  useEffect(() => {
    if (props.value) setCurrentVal(props.value)
  }, [props.value])

  const handlePlaceSelected = () => {
    if (!autocompleteRef.current) return

    const place = autocompleteRef.current.getPlace()
    if (!place.geometry || !place.geometry.location) {
      console.error('Place has no geometry')
      return
    }

    const location = {
      description: place.formatted_address || '',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    }

    props.setSelectedLocationData?.(JSON.stringify(location))
    props.onChange?.(location.description)
  }

  return (
    <div className={classes.autocompleteWrapper}>
      <Autocomplete
        onLoad={(autocomplete) => {
          autocompleteRef.current = autocomplete
        }}
        onPlaceChanged={handlePlaceSelected}
      >
        <TextField
          label={props.label}
          value={currentVal}
          size={props.size}
          error={props.error}
          helperText={props.helperText}
          className={classes.locationSelector}
          fullWidth
          onChange={(e) => setCurrentVal(e.target.value)}
        />
      </Autocomplete>
    </div>
  )
}