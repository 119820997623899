import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import OTPReadComponent from '../../components/signup/OTPReadComponent';
import { useEffect, useState } from 'react';
import { CreateActionModel, UserData } from '../../../store/app/user/types';
import { useTranslation } from 'react-i18next';
import background from "../../../assets/images/background.png";
import logo from "../../../assets/images/logo.svg";
import mobileLogo from "../../../assets/images/logo_mobile.svg";
import UIConstants from '../../../theme/constants/ui-constants';
import { useMediaQuery, useTheme } from '@mui/material';
import { firebase, auth } from '../../../firebaseconfig';
import "firebase/auth";
import { useNavigate } from 'react-router-dom';
import Countdown, { CountdownApi, CountdownRenderProps } from "react-countdown";
import OTPTimeout from '../../components/signup/OTPTimeout';
import { useSnackbar } from 'notistack';
import { AuthData } from '../../../store/app/types';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: UIConstants.rootBgColor,
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
        backgroundPosition: 'center top',
        minHeight: `100vh`,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'row',
            backgroundSize: 'cover',
        },
    },
    logodiv: {
        minHeight: "9rem",
        position: 'fixed',
        top: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            marginTop: "15%",
            position: 'unset',
            top: 'unset',
            width: 'auto'
        }
    },
    logo: {
        height: '40px',
        [theme.breakpoints.up("sm")]: {
            height: '70px',
            marginLeft: "15%",
        },
    },
}));

interface OTPVerifyProps {
    userData: UserData;
    setValidUser: (isValidUser: boolean) => void;
    createUser: (model: CreateActionModel) => void;
    setAuthData: (model: AuthData) => void;
    authData: AuthData;
}


export default function OTPVerify({ userData, setAuthData, authData }: OTPVerifyProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const appLogo = matches ? logo : mobileLogo;
    const [final, setfinal] = useState<firebase.auth.ConfirmationResult | undefined>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    const renderer = ({ minutes, seconds, completed, api }: CountdownRenderProps) => {
        if (completed) {
            return <OTPTimeout onReSendOtp={sendOtp} counterApi={api} />;
        } else {
            return (
                <OTPReadComponent countdown={minutes + ":" + seconds} verifyOtp={verifyOtp} />
            );
        }
    };

    useEffect(() => {
        if (userData.mobile_number) {
            sendOtp();
        }
    }, [userData]);
    
    const sendOtp = (counterApi?: CountdownApi) => {
        if (userData.mobile_number) {
            let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            auth
                .signInWithPhoneNumber(userData.mobile_number, verify)
                .then((result: firebase.auth.ConfirmationResult) => {
                    setfinal(result);
                    counterApi?.start();
                })
                .catch((err: any) => {
                    console.error("Error during OTP verification:", err);
    
                    // Extract error code and message
                    const errorCode = err.code;
                    const errorMessage = err.message;
    
                    // Customize error handling based on error code
                    switch (errorCode) {
                        case 'auth/invalid-phone-number':
                            enqueueSnackbar(t('otpScreens.invalidPhoneNumber'), { variant: 'error' });
                            break;
                        case 'auth/quota-exceeded':
                            enqueueSnackbar(t('otpScreens.quotaExceeded'), { variant: 'error' });
                            break;
                        case 'auth/missing-phone-number':
                            enqueueSnackbar(t('otpScreens.missingPhoneNumber'), { variant: 'error' });
                            break;
                        case 'auth/captcha-check-failed':
                            enqueueSnackbar(t('otpScreens.captchaFailed'), { variant: 'error' });
                            break;
                        default:
                            enqueueSnackbar(t('otpScreens.sentVerificationOTPFailed'), { variant: 'error' });
                            break;

                    }
    
                    // Optionally reload or handle UI updates differently
                    console.error("Error details:", { errorCode, errorMessage });
                });
        }
    };

    const verifyOtp = (code: string) => {
        if (final) {
            const credential = firebase.auth.PhoneAuthProvider.credential(final.verificationId, code);
            firebase.auth().signInWithCredential(credential).then((res) => {
                res?.user?.getIdToken(true).then((token) => {
                    const auth = _.cloneDeep(authData);
                    auth.token = token;
                    auth.isAuthenticated = true;
                    setAuthData(auth);
                    setfinal(undefined);
                    navigate('/signup/personal-info')
                });
            }).catch((error) => {
                console.error(error);
                enqueueSnackbar(t('otpScreens.OTPverificationFailed'), {
                    variant: 'error',
                });
            });
        }

    }



    return (
        <div className={classes.root}>
            <div className={classes.logodiv}> <img
                src={appLogo}
                alt={t('appName')}
                className={classes.logo}
            /></div>
            {final ? <Countdown date={Date.now() + 30000} renderer={renderer} /> : <OTPReadComponent countdown={""} verifyOtp={verifyOtp} />}
        </div>
    );
}
