import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import './assets/i18n/i18n'
import '@fontsource/poppins'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, history } from './configureStore'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {SocketProvider} from './app.v2/business-owner/Settings/views/SocketContext'
import { LoadScript } from '@react-google-maps/api';
const stripePromise = loadStripe('pk_test_51K5vZMBawIx9AGNeAmLKm75HaEmMKkqH8pnA5tyfvJGnTkLVOXemqH7S13Xlc7yFy0UTaKDGonD1ymZzfvynoPED005xfzH2Q6');

ReactDOM.render(
  <SocketProvider>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Elements stripe={stripePromise}>
          <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
              libraries={['places']} // Add the 'places' library
            >
              <App store={store} history={history} />
              </LoadScript>
          </Elements>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </SocketProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
