/**
 * TOSHIBA
 * Copyright(c) TOSHIBA 2021 All Rights Reserved.
 *
 * File Name	: sagas.ts
 * Package	: store
 * Author	: Sajith Rajan
 * Created At	: 01-12-2021
 * Purpose	: ApiListPage store saga file.
 *
 * [Change history]
 *
 * Modified By	:
 * Date		:
 * Description	:
 */
import { all, call, fork, takeEvery, put } from 'redux-saga/effects'
import { ApiResponse, callApi } from '../../../app/utils/api'
import { auth } from '../../../firebaseconfig'
import toaster from '../../../theme/components/Toaster'
import { setIsAuthenticated } from '../actions'

import {
  checkoutSettlement,
  checkoutSubscription,
  connectStripe,
  createStripeCustomer,
  createUser,
  fetchPriceEStimate,
  fetchPriceModel,
  fetchUser,
  fetchUserFlagStatus,
  login,
  saveUserInfo,
  saveBusinessInfo,
  sendVIPMemberReq,
  setCreateUserLoading,
  setUseBathroomLoading,
  setPriceEstimate,
  setPriceModel,
  setUserData,
  setUserFlagStatus,
  updateUserData,
  upgradeUser,
  updateContactUsInfoSucess,
  saveLoginCheckData,
  switchUserTypeForLogin,
  clearLoginCheckData,
  updateZipEligibilityStatus,
  updateSubscriptionStatus,
  updatePackageSubscribed,
  setBusinessTypes,
  setSavingUserInfo,
  fetchBusinessInfo,
  setBusinessInfo,
  updateProfilePicture,
  setProfilePicture,
  setSpecificUser,
  setProductList,
  setUserBusiness,
  setRetrieveSubscriptionData,
  addSubscription,
  setBathroomChallenge,
  setFetchBathroomError,
  updateUserBusinessDiscount,
  setStripeOnboardingStatus,
  getStripeOnboardingStatus,
  // updateSignupFormFlag,
} from './actions'
import { PriceModel, UserActionTypes, UserType } from './types'

const paths = {
  createUser: 'public/user',
  saveUserInfo: 'private/userinfo',
  createStripeCustomer: 'private/userstripe',
  fetchPriceModel: 'private/stripepricelist',
  checkoutSubscription: 'private/usersubscription',
  upgrade: 'private/upgrade',
  connectStripe: 'private/connectstripeaccount',
  login: 'private/login',
  fetchUser: 'private/user',
  fetchUserFlagStatus: 'public/getuserflagstatus',
  fetchPriceEStimate: 'public/getpriceestimate',
  checkoutSettlement: 'private/checkout_settlement',
  sendVipReq: 'public/add_vip_req',
  sendContactUs: 'public/addcontactus',
  saveBusinessInfo: 'public/userBusinessinfo',
  switchLogin: 'public/switchUser',
  declineFlag: 'public/declineFlagStatus',
  waitList: 'public/addWaitlistEmail',
  checkzipcode: 'public/checkzipcode',
  updateSubscriptionStatus: 'public/update_subscription_status',
  updatePackageSubscribed: 'public/update_package_subscribed',
  userDiscounts: 'public/saveUserDiscount',
  businessTypes: 'public/businessTypes',
  businessInfo: 'private/business_info',
  profilePicture: 'public/update_profile_image',
  useBathroom: 'public/use_bathroom',
  getProductList: 'private/getProductLists',
  getUserBusiness: 'private/getUserBusiness',
  getRetrieveSubscriptionData: 'private/retrieveSubscription',
  cancelSubscription: 'private/cancelSubscription',
  upgradeSubscription: 'private/updateSubscription',
  addSubscription:'private/createSubscription',
  bathroomChallenge: 'public/verifyEmail',
  bathroomChallengeCredential: 'public/saveBathroomCredential',
  updateBusinessOwnerDiscount: 'private/updateBusinessOwnerDiscount',
  getStripeOnboardingStatus: 'private/stripeOnboardingStatus',
}

function* handleCreateUser(action: ReturnType<typeof createUser>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    yield put(setCreateUserLoading(true))

    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.createUser,
      action.payload.userData
    )
    if (res.error) {
      if (res.error === 'EMAIL_EXIST') {
        toaster.warning('User with this email already exists. Please Sign In.')
        action.payload.navigate('/login')
      } else if (res.error === 'MOBILE_NUMBER_EXIST') {
        toaster.warning(
          'User with this mobile number already exists. Please Sign In.'
        )
        action.payload.navigate('/login')
      } else {
        toaster.error('Unexpected error occured while create user.')
        action.payload.navigate('/signup')
      }
    } else {
      localStorage.removeItem('tsekmo-subscription')
      // yield put(updateUserData({ key: 'id', value: res.data.user.id }))
      yield put(setIsAuthenticated(true))
      yield put(
        updateUserData({
          key: 'user_type',
          value: action.payload.userData.user_type,
        })
      )
      yield put(
        updateUserData({
          key: 'email',
          value: action.payload.userData.email,
        })
      )
      // action.payload.navigate('/signup/personal-info')
    }
  } catch (err) {
    toaster.error('Unexpected error occured while create user.')
    action.payload.navigate('/signup')
  }
}

function* handleUpdateSubscriptionStatus(
  action: ReturnType<typeof updateSubscriptionStatus>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'get',
      paths.updateSubscriptionStatus,
      action.payload
    )
    if (res.error) {
      // toaster.warning('Failed to Update Status.');
    } else {
      yield put(updateSubscriptionStatus({ user_id: action.payload.user_id }))
      // toaster.warning('Successfully subscribed.')
    }
  } catch (err) {
    // toaster.error('Failed to Update Status.');
  }
}

function* handleUpdatePackageSubscribed(
  action: ReturnType<typeof updatePackageSubscribed>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'get',
      paths.updatePackageSubscribed,
      action.payload
    )
    if (res.error) {
      // toaster.warning('Failed to Update Status.');
    } else {
      // toaster.warning('Successfully subscribed.');
    }
  } catch (err) {
    // toaster.error('Failed to Update Status.');
  }
}

function* handleSaveUserInfo(action: ReturnType<typeof saveUserInfo>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    yield put(setSavingUserInfo(true))
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.saveUserInfo,
      action.payload.reqModel
    )
    if (res.error) {
      console.log(`Check handleSaveUserInfo : ${JSON.stringify(res.error, null, 2)}`);
      toaster.error('Unexpected error occured while save user info.')
      // action.payload.navigate('/signup')
    } else {
      console.log("RES", res)
      yield put(updateUserData({ key: 'id', value: res.data.user.id }))
      yield put(updateUserData({ key: 'hasMultipleRegistration', value: res.data.user.user_info.hasMultipleRegistration }))
      if (
        action.payload.reqModel.user_type ===
        UserType.BUSINESS_OWNER_WITHOUT_SUBSCRIPTION
      ) {
        // yield put(setIsAuthenticated(false));
        // action.payload.navigate('/signup/business-info');

        yield put(
          updateUserData({
            key: 'user_info',
            value: action.payload.reqModel.user_info,
          })
        )
        yield put(
          updateUserData({
            key: 'email',
            value: action.payload.reqModel.user_info.email,
          })
        )
        // action.payload.navigate('/signup/business-info')
      } else {
        // yield put(setIsAuthenticated(true));
        yield put(
          updateUserData({
            key: 'user_info',
            value: action.payload.reqModel.user_info,
          })
        )
        yield put(
          updateUserData({
            key: 'email',
            value: action.payload.reqModel.user_info.email,
          })
        )
        action.payload.navigate('/')
      }
      yield put(setSavingUserInfo(false))
    }
  } catch (err) {
    toaster.error('Unexpected error occured while save user info.')
    action.payload.navigate('/signup')
  }
}

function* handleSaveBusinessInfo(action: ReturnType<typeof saveBusinessInfo>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.saveBusinessInfo,
      action.payload.reqModel
    )
    if (res.error) {
      console.log(`Check handleSaveBusinessInfo : ${JSON.stringify(res.error, null, 2)}`);
      toaster.error('Unexpected error occured while save Business info.')
    } else {
      //yield put(setIsAuthenticated(true));
      // action.payload.navigate('/signup/connect-stripe'); Not for this release
      action.payload.navigate('/')
    }
  } catch (err) {
    toaster.error('Unexpected error occured while save Business info.')
  }
}

function* handleCreateStripeCustomer(
  action: ReturnType<typeof createStripeCustomer>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.connectStripe,
      action.payload
    )
    if (res.error) {
      toaster.error('Unexpected error occured while add stripe customer.')
      // yield put(push('/signup'));
      // window.location.reload();
    } else {
    }
  } catch (err) {
    toaster.error('Unexpected error occured while add stripe customer.')
  }
}

function* handleFetchPriceModel(action: ReturnType<typeof fetchPriceModel>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(callApi, 'get', paths.fetchPriceModel)
    if (res.error) {
      toaster.error('Unexpected error occured while fetching price model.')
      // yield put(push('/signup'));
      // window.location.reload();
    } else {
      if (res.data.prices.data) {
        const priceModels: PriceModel[] = res.data.prices.data.map(
          (priceModel: any) => {
            return {
              price_id: priceModel.id,
              lookup_key: priceModel.lookup_key,
            }
          }
        )
        yield put(setPriceModel(priceModels))
      }
    }
  } catch (err) {
    toaster.error('Unexpected error occured while fetching price model.')
  }
}

function* handleCheckoutSubscription(
  action: ReturnType<typeof checkoutSubscription>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.checkoutSubscription,
      action.payload
    )
    if (res.error) {
      toaster.error('Unexpected error occured while subscribing.')
      // yield put(push('/signup'));
    } else {
      window.location.replace(res.data.url)
    }
  } catch (err) {
    toaster.error('Unexpected error occured while subscribing.')
  }
}

function* handleUpgradeUser(action: ReturnType<typeof upgradeUser>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.upgrade,
      action.payload
    )
    if (res.error) {
      toaster.error('Unexpected error occured while upgrading user.')
    } else {
      yield put(
        updateUserData({
          key: 'user_type',
          value: UserType.BUSINESS_OWNER_WITH_SUBSCRIPTION,
        })
      )
    }
  } catch (err) {
    toaster.error('Unexpected error occured while upgrading user.')
  }
}

function* handleConnectStripe(action: ReturnType<typeof connectStripe>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.connectStripe,
      action.payload
    )
    if (res.error) {
      toaster.error(`${res.error}`)
      // yield put(push('/signup'));
    } else {
      window.location.replace(res.data.url)
    }
  } catch (err) {
    toaster.error('Unexpected error occured while connecting stripe..')
  }
}

function* handleStripeOnboardingStatus(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'get',
      paths.getStripeOnboardingStatus,
      action.payload
    )
    if (res.error) {
      toaster.error(`Getting stripe onboarding status failed: ${res.error}`);
    } else {
      yield put(setStripeOnboardingStatus(res.data))
    }
  } catch (err) {
    toaster.error(`${err}`);
  }
}

function* handleLogin(action: ReturnType<typeof login>) {
  if (localStorage.getItem('tsekmo-subscription')) {
    action.payload.reqData.package_subscribed =
      localStorage.getItem('tsekmo-subscription') ?? ''
  }
  try {
    console.log(`handleLogin : ${JSON.stringify(action.payload.reqData)}`)
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.login,
      action.payload.reqData
    )
    if (res.error) {
      if (res.error === 'USER_NOT_FOUND') {
        toaster.warning('User not found. Please Sign up first.')
        action.payload.navigate('/signup')
      } else {
        toaster.error('Unexpected error occured while login.')
        yield put(setIsAuthenticated(false))
        action.payload.navigate('/login')
      }
    } else {
      yield put(updateUserData({ key: 'hasMultipleRegistration', value: res.data.hasMultipleRegistration }))
      localStorage.removeItem('tsekmo-subscription')
      if (!res.data.hasMultipleRegistration) {
        
        yield put(setIsAuthenticated(true))
        //yield put(setIsAuthenticated(false));
        yield put(updateUserData({ key: 'id', value: res.data.id }))
        yield put(
          updateUserData({
            key: 'user_type',
            value: res.data.user_type,
          })
        )
        yield put(
          updateUserData({
            key: 'country_code',
            value: res.data.country_code,
          })
        )
        yield put(
          updateUserData({
            key: 'email',
            value: action.payload.reqData.email,
          })
        )
        action.payload.navigate('/')
      } else {
        yield put(setIsAuthenticated(false))
        yield put(saveLoginCheckData(res.data))
        action.payload.navigate('/login/user-type')
      }
    }
  } catch (err) {
    console.error(err)
    yield put(setIsAuthenticated(false))
    toaster.error(
      'Unexpected error occured while connecting to server for login.'
    )
    action.payload.navigate('/login')
  }
}

function* handleSwitchLoginUserType(
  action: ReturnType<typeof switchUserTypeForLogin>
) {
  try {
    yield put(setIsAuthenticated(false))
    console.log(`Check for the payload : ${JSON.stringify(action.payload.reqData)}`)
    const res: ApiResponse = yield call(
      callApi,
      'get',
      paths.switchLogin,
      action.payload.reqData
    )
    if (res.error) {
      toaster.error('Unexpected error occured while switch login.')
      yield put(setIsAuthenticated(false))
    } else {
      yield put(setIsAuthenticated(true))
      yield put(updateUserData({ key: 'id', value: res.data.id }))
      yield put(
        updateUserData({
          key: 'user_type',
          value: res.data.user_type,
        })
      )
      yield put(
        updateUserData({
          key: 'country_code',
          value: res.data.country_code,
        })
      )
      yield put(
        updateUserData({
          key: 'email',
          value: action.payload.reqData.email,
        })
      )
      yield put(clearLoginCheckData())
      if (action.payload.reqData.user_type === 2) {
        action.payload.navigate('/customer')
      } else {
        action.payload.navigate('/business-owner')
      }
    }
  } catch (err) {
    console.error(err)
    yield put(setIsAuthenticated(false))
    toaster.error(
      'Unexpected error occured while connecting to server for login.'
    )
    action.payload.navigate('/login')
  }
}

function* handleFetchUserData(action: ReturnType<typeof fetchUser>) {
  try {
    const res: ApiResponse = yield call(callApi, 'get', paths.fetchUser, action.payload)
    if (res.error) {
      toaster.error('Unable to fetch user data.')
      // yield put(setIsAuthenticated(false));
      // action.payload.navigate('/login');
    } else {
      yield put(setUserData(res.data?.userData))
    }
  } catch (err) {
    toaster.error('Unable to fetch user data.')
  }
}

function* handleFetchUserFlagStatus(
  action: ReturnType<typeof fetchUserFlagStatus>
) {
  try {
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.fetchUserFlagStatus,
      action.payload
    )
    if (res.error) {
      toaster.error('Unable to fetch user flag details.')
    } else {
      yield put(setUserFlagStatus(res.data?.flags))
    }
  } catch (err) {
    toaster.error('Unable to fetch user flag details.')
  }
}

function* handleFetchPriceEstimateToPay(
  action: ReturnType<typeof fetchPriceEStimate>
) {
  try {
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.fetchPriceEStimate,
      action.payload
    )
    if (res.error) {
      toaster.error('Unable to fetch user flag details.')
    } else {
      yield put(setPriceEstimate(res.data?.estimate))
    }
  } catch (err) {
    toaster.error('Unable to fetch user flag details.')
  }
}

function* handleCheckouSettlement(
  action: ReturnType<typeof checkoutSettlement>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.checkoutSettlement,
      action.payload
    )
    if (res.error) {
      toaster.error('Payment Failed.')
      // yield put(push('/signup'));
    } else {
      window.location.replace(res.data.url)
    }
  } catch (err) {
    toaster.error('Payment Failed.')
  }
}

function* handleVipMemberReq(action: ReturnType<typeof sendVIPMemberReq>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.sendVipReq,
      action.payload.data
    )
    if (res.error) {
      toaster.error('Failed to send request')
      action.payload.navigate('/signup')
    } else {
      toaster.success('Request sent successfully.')
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleContactUs(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.sendContactUs,
      action.payload
    )
    if (res.error) {
      toaster.error('Failed to send request')
    } else {
      toaster.success('Message sent successfully, We will contact you')
      yield put(updateContactUsInfoSucess(true))
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleDeclineFlagStatus(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.declineFlag,
      action.payload
    )
    if (res.error) {
      toaster.error('Failed to send request')
    } else {
      yield put(fetchUserFlagStatus(action.payload.user_id))
      toaster.success('Successfully update the flag status')
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleCheckZipCode(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(callApi, 'get', paths.checkzipcode, {
      zip: action.payload.zipCode,
      business: action.payload.business,
      from: action.payload.fromParam,
    })
    if (res.error) {
      toaster.error('Failed check the zip code eligibility')
    } else {
      const result: any = res.data
      const numberOfHost = result.list.length
      const isValid = numberOfHost < 1
      const currentDate = new Date()
      let redirectUrl = 'https://www.tsekmo.com'
      let failedUrl = ''
      //CHANGE THIS ON FIRST DAY
      console.log(
        'CURRENT DATE',
        currentDate,
        new Date('2023-12-30'),
        currentDate <= new Date('2023-12-30')
      )
      if (action.payload.fromParam == 'basic') {
        redirectUrl =
          currentDate <= new Date('2023-12-30')
            ? process.env.REACT_APP_LAUNCH_URL_BASIC_FIRSTDAY || redirectUrl
            : process.env.REACT_APP_LAUNCH_URL_BASIC_SECONDDAY || redirectUrl

        failedUrl = process.env.REACT_APP_ZIP_CHECK_FAILED_BASIC || failedUrl
      } else {
        redirectUrl =
          currentDate <= new Date('2023-12-30')
            ? process.env.REACT_APP_LAUNCH_URL_ANTIVANDAL_FIRSTDAY ||
              redirectUrl
            : process.env.REACT_APP_LAUNCH_URL_ANTIVANDAL_SECONDDAY ||
              redirectUrl
        failedUrl =
          process.env.REACT_APP_ZIP_CHECK_FAILED_ANTIVANDAL || failedUrl
      }

      if (isValid) {
        toaster.message(
          'Congratulations you are eligible to receive the Early Bird Price in your zip code to one year free. Please Register as soon as possible to be the first one to grab Tsekmo’s launch offer, while it lasts!',
          'success',
          8000,
          { vertical: 'top', horizontal: 'center' }
        )
        window.location.replace(redirectUrl + '?zip=' + action.payload.zipCode)
      } else {
        toaster.message(
          'Sorry! but you can still take advantage of our launch promotional offering that will happen.',
          'success',
          8000,
          { vertical: 'top', horizontal: 'center' }
        )
        window.location.replace(failedUrl + '?zip=' + action.payload.zipCode)
      }

      // action.payload.navigate({
      //   pathname: 'https://www.tsekmo.com/order-form-2-sales-page-1-summit-funnel-early-bird-price-0-99-for-1-year',
      //   search:'?isHost=true',
      // });

      yield put(updateZipEligibilityStatus(isValid))
      // yield put(updateSignupFormFlag(isValid));
    }
  } catch (err) {
    console.log(err)
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleSetWaitlistEmail(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.waitList,
      action.payload
    )
    if (res.error) {
      toaster.error('Failed to add to waitlist')
    } else {
      toaster.success('Successfully added your email to waitlist')
      action.payload.navigate({
        pathname: '/landing',
      })
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleSetUserDiscounts(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.userDiscounts,
      action.payload
    )
    if (res.error) {
      toaster.error('Failed to save settings')
    } else {
      toaster.success('Successfully saved settings!')
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleFetchBusinessTypes() {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(callApi, 'get', paths.businessTypes)
    if (res.error) {
      toaster.error('Failed to fetch business types')
    } else {
      yield put(setBusinessTypes(res.data))
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleFetchBusinessInfo(
  action: ReturnType<typeof fetchBusinessInfo>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(callApi, 'get', paths.businessInfo, {
      user_id: action.payload,
    })
    if (res.error) {
      toaster.error('Failed to fetch User Business Information')
    } else {
      yield put(setBusinessInfo(res.data))

    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleUpdateProfilePicture(
  action: ReturnType<typeof updateProfilePicture>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.profilePicture,
      action.payload,
      {
        'Content-Type': 'multipart/form-data',
      }
    )
    if (res.error) {
      toaster.error('Failed to update profile picture')
    } else {
      yield put(setProfilePicture(res.data.image_url))
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleUseBathroom(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    yield put(setUseBathroomLoading(true))

    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.useBathroom,
      action.payload,
      {
        'Content-Type': 'multipart/form-data',
      }
    )
    if (res.error) {
      toaster.error('Failed to submit bathroom request')
      yield put(setUseBathroomLoading(false))
    } else {
      yield put(setUseBathroomLoading(false))
      toaster.success('Successfully submitted bathroom request')
    }
  } catch (err) {
    yield put(setUseBathroomLoading(false))
    toaster.error('Failed to submit bathroom request')
  }
}

function* handleGetSpecificUser(action: any) {
  try {
    const res: ApiResponse = yield call(callApi, 'get', paths.fetchUser, {
      user_id: action.payload,
    })
    if (res.error) {
      toaster.error('Unable to fetch user data.')
      // yield put(setIsAuthenticated(false));
      // action.payload.navigate('/login');
    } else {
      yield put(setSpecificUser(res.data?.userData))
    }
  } catch (err) {
    toaster.error('Unable to fetch user data.')
  }
}

/**
 * Api Call for Product Catalog on Stripe
 */
function* handleGetProductList(action : any) {
  try {
    const { token, limit, user_type, is_app_subscription } = action.payload;

    // Construct the payload conditionally
    const payload: any = { limit };
    if (user_type !== undefined) {
      payload.user_type = user_type;
    }
    if (is_app_subscription !== undefined) {
      payload.is_app_subscription = is_app_subscription;
    }

    // Make the API call with the constructed payload
    const res: ApiResponse = yield call(callApi, 'get', paths.getProductList, payload, {}, {}, token);
    if (res.error) {
      toaster.error('Unexpected error occured while fetching product list.')
      console.error(JSON.stringify(res.error));
    } else {
      if (res.data) {
        yield put(setProductList(res.data.products))
      }
    }
  } catch (err) {
    toaster.error('Unexpected error occured while fetching price model.')
  }
}

function* handleUserBusinessStripe(action: any) {
  try {
    const res: ApiResponse = yield call(callApi, 'get', paths.getUserBusiness, {
      user_id: action.payload,
    })
    if (res.error) {
      toaster.error('Failed to fetch User Business')
    } else {
      yield put(setUserBusiness(res.data))
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleUserRetrieveSubscription(action: any) {
  try {
    const { user_id, token } = action.payload;
    const res: ApiResponse = yield call(callApi, 'get', paths.getRetrieveSubscriptionData, {
      user_id: user_id
    }, {}, {}, token)
    if(res.error) {
        // toaster.error('Failed to retrieve subscription data')
        yield put(setRetrieveSubscriptionData(null));
    } else {
      if (res.data) {
        yield put(setRetrieveSubscriptionData(res.data))
      }
    }
  } catch (error) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleCancelSubscription(action: any) {
  try {
    const { user_id } = action.payload;
    if(!user_id) {
      toaster.error('User ID is missing');
      return;
    }

    const res: ApiResponse = yield call(callApi, 'get', paths.cancelSubscription, {
      user_id: user_id
    });
    if (res.error) {
      toaster.error('Failed to cancel subscription');
    } else {
      toaster.success('Subscription cancelled successfully')
      yield put(setRetrieveSubscriptionData(res.data))
    }
  } catch (error) {
    toaster.error('Unexpected error occurred while cancelling subscription');
  }
}

function* handleUpgradeSubscription(action: any) {
  try {
    const { user_id, subitem_id, price_id, token} = action.payload;
    if(!user_id) {
      toaster.error('User ID is missing');
      return;
    }

    const res: ApiResponse = yield call(callApi, 'get', paths.upgradeSubscription, {
      user_id: user_id,
      subitem_id: subitem_id,
      price_id: price_id
    }, {},{}, token);
    if (res.error) {
      toaster.error('Failed to upgrade subscription');
    } else {
      toaster.success('Subscription upgrade successfully')
      yield put(setRetrieveSubscriptionData(res.data))
    }
  } catch (error) {
    toaster.error('Unexpected error occurred while upgrading subscription');
  }
}

function* handleAddSubscription(action: any) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const {token} = action.payload;
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.addSubscription,
      action.payload,
      {
        'Content-Type': 'application/json',
      },{},token
    )
    if (res.error) {
      toaster.error('Failed to create subscription')
    } else {
      console.log(`Check : ${JSON.stringify(res.data)}`)
      window.location.replace(res.data.url)
    }
  } catch (err) {
    toaster.error('Unexpected error occured while sending request')
  }
}

function* handleBathroomChallenge(action : any) {
  try {
    const res: ApiResponse = yield call(callApi, 'get', paths.bathroomChallenge, action.payload);
    if (res.error) {
      toaster.error(`Error: ${res.error}`)
      yield put (setFetchBathroomError(true));
    } else {
      if (res.data) {
        yield put(setBathroomChallenge(res.data))
        yield put (setFetchBathroomError(false));
      }
    }
  } catch (err) {
    toaster.error('Unexpected error occured while fetching Bathroom Challenge Data');
  }
}

function* handleSaveBathroomCredential(action: any) {
  try {
    const data = {
      email: action.payload.email,
      mobile_number: action.payload.mobileNumber,
      country_code: action.payload.country_code
    }

    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.bathroomChallengeCredential,
      data
    )
    if (res.error) {
      toaster.error(`Error: ${res.error}`);
    } else {
      toaster.success('Successfully Registered for Bathroom Challenge')
    }
  } catch (err) {
    toaster.error('Unexpected error occured while saving Bathroom Credential.')
  }
}

function* handleUpdateUserBusinessDiscount(action: any) {
  try {
    const data = action.payload

    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'put',
      paths.updateBusinessOwnerDiscount,
      data
    )
    if (res.error) {
      toaster.error(`${res.error}`)
    } else {
      toaster.success(`${res.data.msg}`);
    }
  } catch (err) {
    toaster.error(`Unexpected error occured while sending request : ${err}`)
  }
}

function* watchVipMemberReq() {
  yield takeEvery(UserActionTypes.SEND_VIP_REQUEST, handleVipMemberReq)
}

function* watchSendContactUs() {
  yield takeEvery(UserActionTypes.SEND_CONTACT_US_INFO, handleContactUs)
}

function* watchSaveUserInfo() {
  yield takeEvery(UserActionTypes.SAVE_USER_INFO, handleSaveUserInfo)
}

function* watchSaveBusinessInfo() {
  yield takeEvery(UserActionTypes.SAVE_BUSINESS_INFO, handleSaveBusinessInfo)
}

function* watchCreateUser() {
  yield takeEvery(UserActionTypes.CREATE_USER, handleCreateUser)
}

function* watchConnectStripe() {
  yield takeEvery(UserActionTypes.CONNECT_STRIPE, handleConnectStripe)
}

function* watchGetSpecificUser() {
  yield takeEvery(UserActionTypes.GET_SPECIFIC_USER, handleGetSpecificUser)
}

function* watchFetchBusinessTypes() {
  yield takeEvery(
    UserActionTypes.FETCH_BUSINESS_TYPES,
    handleFetchBusinessTypes
  )
}

function* watchSubscribe() {
  yield takeEvery(
    UserActionTypes.CHECKOUT_SUBSCRIPTION,
    handleCheckoutSubscription
  )
}

function* watchUpdateSubscriptionStatus() {
  yield takeEvery(
    UserActionTypes.UPDATE_SUBSCRIPTION_STATUS,
    handleUpdateSubscriptionStatus
  )
}

function* watchUpdatePackageSubscribed() {
  yield takeEvery(
    UserActionTypes.UPDATE_PACKAGE_SUBSCRIBED,
    handleUpdatePackageSubscribed
  )
}

function* watchUpgradeUser() {
  yield takeEvery(UserActionTypes.UPGRADE_USER, handleUpgradeUser)
}

function* watchFetchPriceModel() {
  yield takeEvery(UserActionTypes.FETCH_PRICE_MODEL, handleFetchPriceModel)
}

function* watchLoginData() {
  yield takeEvery(UserActionTypes.LOGIN, handleLogin)
}

function* watchSwitchLoginData() {
  yield takeEvery(
    UserActionTypes.SWITCH_USER_FOR_LOGIN,
    handleSwitchLoginUserType
  )
}

function* watchLogout() {
  yield takeEvery(UserActionTypes.LOGOUT, () => {
    auth
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      })
  })
}

function* watchFetchUser() {
  yield takeEvery(UserActionTypes.FETCH_USER, handleFetchUserData)
}

function* watchCreatStripeCustomer() {
  yield takeEvery(
    UserActionTypes.CREATE_STRIPE_CUSTOMER,
    handleCreateStripeCustomer
  )
}

function* watchFetchUserFlagStatus() {
  yield takeEvery(
    UserActionTypes.FETCH_USER_FLAGGING,
    handleFetchUserFlagStatus
  )
}

function* watchFetchPriceEstimateToPay() {
  yield takeEvery(
    UserActionTypes.FETCH_PRICE_ESTIMATE,
    handleFetchPriceEstimateToPay
  )
}

function* watchCheckouSettlement() {
  yield takeEvery(UserActionTypes.SETTLEMENT, handleCheckouSettlement)
}

function* watchDeclineFlagStatus() {
  yield takeEvery(
    UserActionTypes.DECLINE_USER_FLAGGING,
    handleDeclineFlagStatus
  )
}

function* watchCheckZipCode() {
  yield takeEvery(UserActionTypes.ZIP_CODE_CHECK, handleCheckZipCode)
}

function* watchSetWaitlistEmail() {
  yield takeEvery(UserActionTypes.SET_WAITLIST_EMAIL, handleSetWaitlistEmail)
}

function* watchSetUserDiscounts() {
  yield takeEvery(UserActionTypes.SET_USER_DISCOUNTS, handleSetUserDiscounts)
}

function* watchHandleFetchBusinessInfo() {
  yield takeEvery(UserActionTypes.FETCH_BUSINESS_INFO, handleFetchBusinessInfo)
}

function* watchHandleGetProductList() {
  yield takeEvery(UserActionTypes.GET_PRODUCT_LIST, handleGetProductList)
}

function* watchHandleUpdateProfilePicture() {
  yield takeEvery(
    UserActionTypes.UPDATE_PROFILE_PICTURE,
    handleUpdateProfilePicture
  )
}

function* watchUseBathroom() {
  yield takeEvery(UserActionTypes.USE_BATHROOM, handleUseBathroom)
}

function* watchHandleUserBusinessStripe() {
  yield takeEvery(
    UserActionTypes.USER_BUSINESS_STRIPE,
    handleUserBusinessStripe
  )
}

function* watchHandleRetrieveSubscription() {
  yield takeEvery(
    UserActionTypes.USER_RETRIEVE_SUBSCRIPTION,
    handleUserRetrieveSubscription
  )
}

function* watchCancelSubscription() {
  yield takeEvery(UserActionTypes.USER_CANCEL_SUBSCRIPTION, handleCancelSubscription);
}

function* watchUpgradeSubscription() {
  yield takeEvery(UserActionTypes.USER_UPGRADE_SUBSCRIPTION, handleUpgradeSubscription);
}

function* watchAddSubscription() {
  yield takeEvery(UserActionTypes.USER_CREATE_SUBSCRIPTION, handleAddSubscription);
}

function* watchBathroomChallenge() {
  yield takeEvery(UserActionTypes.BATHROOM_CHALLENGE, handleBathroomChallenge);
}

function* watchSaveBathroomCredential() {
  yield takeEvery(UserActionTypes.SAVE_BATHROOM_CREDENTIAL, handleSaveBathroomCredential);
}

function* watchUpdateUserBusinessDiscount () {
  yield takeEvery(UserActionTypes.UPDATE_USER_BUSINESS_DISCOUNT, handleUpdateUserBusinessDiscount);
}

function* watchStripeOnboardingStatus () {
  yield takeEvery(UserActionTypes.STRIPE_ONBOARDING_STATUS, handleStripeOnboardingStatus);
}
// We can also use `fork()` here to split our saga into multiple watchers.
function* userSagas() {
  yield all([
    fork(watchGetSpecificUser),
    fork(watchUseBathroom),
    fork(watchHandleUpdateProfilePicture),
    fork(watchHandleFetchBusinessInfo),
    fork(watchFetchBusinessTypes),
    fork(watchCreateUser),
    fork(watchSaveUserInfo),
    fork(watchCreatStripeCustomer),
    fork(watchFetchPriceModel),
    fork(watchSubscribe),
    fork(watchConnectStripe),
    fork(watchLoginData),
    fork(watchFetchUser),
    fork(watchLogout),
    fork(watchUpgradeUser),
    fork(watchUpdatePackageSubscribed),
    fork(watchFetchUserFlagStatus),
    fork(watchFetchPriceEstimateToPay),
    fork(watchCheckouSettlement),
    fork(watchVipMemberReq),
    fork(watchSendContactUs),
    fork(watchSaveBusinessInfo),
    fork(watchSwitchLoginData),
    fork(watchDeclineFlagStatus),
    fork(watchCheckZipCode),
    fork(watchSetWaitlistEmail),
    fork(watchSetUserDiscounts),
    fork(watchUpdateSubscriptionStatus),
    fork(watchHandleGetProductList),
    fork(watchHandleUserBusinessStripe),
    fork(watchHandleRetrieveSubscription),
    fork(watchCancelSubscription),
    fork(watchUpgradeSubscription),
    fork(watchAddSubscription),
    fork(watchBathroomChallenge),
    fork(watchSaveBathroomCredential),
    fork(watchUpdateUserBusinessDiscount),
    fork(watchStripeOnboardingStatus),
  ])
}

export default userSagas
