import {
  Avatar,
  FormControl,
  Box,
  Select,
  MenuItem,
  Card,
  Tabs,
  Tab,
  SelectChangeEvent,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications'
import UIConstants from '../../../../theme/constants/ui-constants'

import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Information from './Information'
import Customers from './Customers'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { fetchListings } from '../../../../store/app/listing/actions'
import { fetchBusinessTypes } from '../../../../store/app/user/actions'
import { fetchDashboardData } from '../../../../store/app/business_dashboard/actions'
import CleanLogHome from './CleanLogHome'
import BillList from './BillList'
import ListingSettings from '../components/ListingSettings'
import moment from 'moment'

const useStyle = makeStyles((theme) => ({
  tabs: {
    marginLeft: '4rem',
    '& .MuiTab-root': {
      fontSize: '12px',
      textTransform: 'capitalize',
      paddingBottom: 0,
    },
    '& .MuiTab-root.Mui-selected': {
      color: UIConstants.accent,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: UIConstants.accent,
    },
  },
}))

function Dashboard() {
  const classes = useStyle()
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState<number>(0)
  const [currentListing, setCurrentListing] = useState<any>()
  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userData
  )
  const { listingData } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const { dashboardData } = useSelector(
    (state: ApplicationState) => state.businessDashboard
  )
  const { userData } = useSelector((state: ApplicationState) => state.app)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    if (userData.id) {
      dispatch(fetchListings(userData.id))
    }
  }, [userData, dispatch])

  useEffect(() => {
    if (listingData.length > 0) {
      setCurrentListing(listingData[0])
    }
  }, [listingData])

  useEffect(() => {
    if (currentListing?.id) {
      dispatch(
        fetchDashboardData({
          listing_id: currentListing.id,
          time_frame: '1D',
          userTimezone: 'Asia/Manila',
        })
      )
    }
  }, [currentListing, dispatch])

  useEffect(() => {
    console.log(`Check dashboard data :  ${JSON.stringify(dashboardData, null, 2)}`)
  }, [dashboardData])

  const handleChangeListing = (event: any) => {
    setCurrentListing(event.target.value)
  }

  const renderContent = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return <Information listing={currentListing} />
      case 1:
        return <Customers listing_id={currentListing && currentListing.id} />
      case 2:
        return <CleanLogHome listing_id={currentListing && currentListing.id} />
      case 3:
        return <BillList listing_id={currentListing && currentListing.id} />
      case 4:
        return <ListingSettings />
      default:
        return <Information listing={currentListing}/>
    }
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="row-span-1">
        <div className="flex items-center w-full p-10 text-mainText">
          {currentUser && currentUser.user_info?.image_url ? (
            <img
              className="w-[50px] h-[50px] rounded-full"
              src={currentUser.user_info?.image_url}
              alt="user"
              referrerPolicy="no-referrer"
            />
          ) : (
            <Avatar className="w-[50px] h-[50px]" />
          )}
          <div className="flex flex-col ml-5">
            <h1 className="text-2xl font-bold">
              Welcome back, {currentUser && currentUser.user_info?.first_name}!
            </h1>
            <h2 className="text-xs flex items-center gap-1 text-subText">
              {/* <NotificationsIcon sx={{ width: '16px', height: '16px' }} />
              You have 2 new notifications */}
            </h2>
          </div>
        </div>
      </div>

      <div className="mt-4 h-full pb-10 w-full relative bg-foreground border-t-[1px]">
        {listingData.length > 0 ? (
          <>
            <div className="px-2 w-64 min-h-[40px] rounded-t-xl border-t-[1px] border-l-[1px] border-r-[1px] bg-foreground absolute left-0 top-0 -mt-[39px] ml-[50px]">
              {currentListing && (
                <FormControl fullWidth>
                  <Select
                    value={currentListing}
                    onChange={handleChangeListing}
                    sx={{
                      boxShadow: 'none',
                      height: '40px',
                      outline: 'none',
                      fontSize: '0.9rem',
                      fontWeight: 'medium',
                      color: UIConstants.mainText,
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        {
                          border: 0,
                        },
                    }}
                  >
                    {listingData.map((item: any) => (
                      <MenuItem key={item.id} value={item}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="w-full h-full">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                className={classes.tabs}
              >
                <Tab label="Home" />
                <Tab label="Customers Today" />
                <Tab label="Clean Logs" />
                <Tab label="Bill List" />
                <Tab label="Settings" />
              </Tabs>
              {renderContent(tabValue)}
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center text-xl  text-mainText w-full h-1/2 px-64 text-center">
            <h1>
              You currently have no listings. Start by creating one to monitor
              your listing data.
            </h1>
          </div>
        )}
      </div>
      <div className="h-[75px] px-10 flex items-center font-bold text-xs">
        {`(C) Tsekmo ${moment().year()} TSEKMO. Patent Pending. All rights reserved.`}
      </div>
    </div>
  )
}

export default Dashboard
