import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

interface DiscountModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string, value: number | null) => void;
}

const DiscountModal: React.FC<DiscountModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState<string>('');
  const [value, setValue] = useState<number | null>(null);

  const handleSubmit = () => {
    onSubmit(name, value);
    setName('');
    setValue(null);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add Discount</DialogTitle>
      <DialogContent>
        <TextField
          label="Discount Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Default Value"
          type="number"
          value={value === null ? '' : value}
          onChange={(e) => setValue((e.target.value === '' ? null : Number(e.target.value)))}
          fullWidth
          margin="normal"
          inputProps={{ min: 0, max: 100 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountModal;